<template>
    <router-view v-if="forum !== null" :forumProp="forum" :communityID="communityID" :communityOwner="communityOwner" :communityName="communityName"></router-view>
</template>

<script>
    import * as axios from '@/axios-auth'
    // import JitapEditor from "../../../components/JitapEditor";
    export default {
        name: "CommunityForum",
        // components: {JitapEditor},
        props: ["communityID", "communityOwner", "communityName"],
        data(){
            return {
                forum: null
            }
        },
        created(){
            this.fetchForums()
        },
        methods: {
            fetchForums(){
                let loader = this.$loading.show({
                    onCancel: this.onLoaderCancel,
                });
                axios.jigo.get(`/v2/community/integrations/forum/getCommunityForums/${this.communityOwner}/${this.communityName}`)
                .then((resp) => {
                    this.forum = resp.data[0]
                    loader.hide()
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERORR: Fetching forums FAILED", err)
                })
            },
            newPost(){
                this.$router.push({name: "newPost"})
            }
        }
    }
</script>

<style scoped>

</style>
